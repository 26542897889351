jQuery(document).ready(function($) {

  displayForm($("#contact--trigger select").val());

  function display(elem){
    $('#contact--' + elem).slideToggle(400);
  }

  $("#contact--trigger select").change(function(){
      displayForm($(this).val())
  });

  function displayForm(contact_choice) {
      $('.contact-form:visible').slideToggle(200);
      display(contact_choice);
  }

});
