jQuery(document).ready(function($) {

  /* Set the width of the side navigation to 250px and the left margin of the page content to 250px */
  function openNav() {
    $("#side-nav").css('width',"19rem");
    $("#main").css('left',"19rem");
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  function closeNav() {
    $("#side-nav").css('width',"0rem");
    $("#main").css('left',"0rem");
  }

  $('.navigation-toggler--reactive-area').on('click',function(e){
    e.preventDefault();
    if ( $(this).hasClass('active') ) {
      closeNav();
    } else {
      openNav();
    }
    $(this).toggleClass('active');
  });

  $('.side-nav--closebtn').on('click',function(e){
    e.preventDefault();
    $('.navigation-toggler--reactive-area').removeClass('active');
    closeNav();
  });


  //
  // show/hide sub-menus
  //
  if ($('.sub-menu-trigger + ul').length > 0){ // Is there any sub-menu?
    $('.sub-menu-trigger + ul').each(function(){ $(this).hide(); }); // Then whe start by hidding all of them on load
    $('.sub-menu-trigger').on('click',function(e){ // Click on a trigger
      e.preventDefault();
      var currentMenu = $(this).next('ul'); // identify current menu
      var isCurrentMenuVisible = $(this).next('ul').is(':visible'); // is current menu visible?

      if (isCurrentMenuVisible) {
        $(currentMenu).slideToggle();
      }
      else {
        $(this).closest('ul').find('li .sub-menu-trigger + ul:visible').slideToggle(); // hide all visible submenu, not current
        // toggle the visibility of current menu: visible <--> invisible
        $(this).next('ul').slideToggle();
      }
    });
  }

});
