// Icons => FontAwesome
require('font-awesome/css/font-awesome.min.css');
require('./medias/fonts/ilec/css/ilec-embedded.css');

// Select2
require('select2/dist/js/select2.min.js');
require('select2/dist/js/i18n/fr.js');
require('select2/dist/css/select2.css');

require('./scss/main.scss');

// Bootstrap
require('bootstrap4/js/src/util.js');
require('bootstrap4/js/src/carousel.js');
require('bootstrap4/js/src/alert.js');

require('./js/off-canvas-menu.js');
require('./js/touch-screens-bootstrap-carousel.js');
require('./js/home-carousel.js');
require('./js/init-select2-fields.js');
require('./js/contact-switcher.js');
require('./js/cookies.js');


if (module.hot) {
    module.hot.accept();
}
